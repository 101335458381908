import React, { type ReactNode } from "react";
import {
  NavLink as RouterNavLink,
  type NavLinkProps as RouterNavLinkProps,
  type To,
  useLocation,
} from "react-router-dom";

import {
  type NavLinkProps as MantineNavLinkProps,
  NavLink,
  Text,
  ThemeIcon,
} from "@mantine/core";

import classes from "./MenuItem.module.css";

type OmittedRouterProps =
  | "className"
  | "style"
  | "children"
  | "color"
  | "onChange";
type OmittedMantineProps =
  | "label"
  | "icon"
  | "component"
  | "to"
  | "leftSection"
  | "classNames";

interface MenuItemProps
  extends Omit<RouterNavLinkProps, OmittedRouterProps>,
    Omit<MantineNavLinkProps, OmittedMantineProps> {
  label: string | ReactNode;
  icon?: React.ComponentType<{ size: number }>;
  to: To;
  children?: ReactNode | ReactNode[];
  description?: string;
  rightSection?: ReactNode;
}

export const MenuItem = ({
  children,
  label,
  icon: Icon,
  to,
  ...rest
}: MenuItemProps) => {
  const location = useLocation();
  const isTopLevel = !!Icon;

  const isActive = React.useMemo(() => {
    if (typeof to === "string") {
      // Exact match for the current path
      if (location.pathname === to) return true;

      // Check if it's a parent route and the current path starts with it
      if (children && location.pathname.startsWith(to)) return true;

      // If it has children, check if any child path is active
      if (children) {
        return React.Children.toArray(children).some(
          (child) =>
            React.isValidElement(child) &&
            typeof child.props.to === "string" &&
            location.pathname.startsWith(child.props.to),
        );
      }
    }
    return false;
  }, [location.pathname, to, children]);

  return (
    <NavLink
      defaultOpened={isActive}
      classNames={classes}
      variant="subtle"
      component={RouterNavLink}
      to={to}
      label={
        isTopLevel ? (
          <Text fw={500} truncate="end">
            {label}
          </Text>
        ) : (
          label
        )
      }
      leftSection={
        isTopLevel && (
          <ThemeIcon variant="light" size={32}>
            <Icon size={18} />
          </ThemeIcon>
        )
      }
      disableRightSectionRotation={true}
      {...rest}
    >
      {children}
    </NavLink>
  );
};
