import { z } from "zod";

export const LLMRecommendationsSchema = z.object({
  starter: z.string(),
  explanation: z.string(),
  type: z.string(),
});

export type LLMRecommendationsSchemaType = z.infer<
  typeof LLMRecommendationsSchema
>;
