import { fetchAPIWithToken } from "@hooks/use-client";
import EmptyTablesImage from "@images/empty-tables.svg";
import {
  Button,
  Card,
  Center,
  Group,
  Image,
  Loader,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import {
  ArtifactTableDataSchema,
  type ArtifactTableData,
} from "@mm/shared/schemas/artifact";
import type { ArtifactDb, InsightDb } from "@mm/shared/schemas/insights";
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type { SortingState } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { z } from "zod";
import { useArtifactIdParam } from "./Chat/ArtifactMessage";
import { Table, type TableFilters } from "./Table";

import { useArtifact } from "@api/useArtifact";
import { BiReset } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { ArtifactsList } from "./ArtifactsList";
import { RestartModal } from "./RestartModal";
import classes from "./Tabs.module.css";
import { ResultsVerification } from "./Verification";

const ColumnsResultParser = z.object({
  data: z.object({ name: z.string() }).array(),
});

export interface ColumnsResult {
  data: z.infer<typeof ColumnsResultParser>["data"] | undefined;
  isPending: boolean;
}

const DataResultParser = z.object({
  data: z.record(z.unknown()).array(),
});

export interface DataResult {
  data: z.infer<typeof DataResultParser>["data"] | undefined;
  isPending: boolean;
}

const EmptyArtifactTable = () => (
  <Stack style={{ textAlign: "center" }} gap={"sm"}>
    <Image
      m={"auto"}
      w={125}
      alt="Empty messages"
      src={EmptyTablesImage}
      mb="lg"
    />
    <Text c={"dimmed"}>This artifact is empty</Text>
    <Text c={"dimmed"}>Keep chatting to create new one </Text>
  </Stack>
);

const EmptyReportTable = ({ insight }: { insight: InsightDb }) => (
  <Stack style={{ textAlign: "center" }} gap={"sm"}>
    <Image
      m={"auto"}
      w={125}
      alt="Empty messages"
      src={EmptyTablesImage}
      mb="lg"
    />
    <Text c={"dimmed"}>This report is empty</Text>
    <Group gap={4}>
      <Text c="dimmed">Go back to the</Text>
      <Button
        pl={0}
        component={NavLink}
        style={{ lineHeight: "0" }}
        size={"compact-md"}
        to={`/report/${insight.report_id}`}
        variant="transparent"
      >
        data preparation
      </Button>
    </Group>
  </Stack>
);

const useResultsColumns = (insightId: InsightDb["id"]): ColumnsResult => {
  return useQuery({
    queryKey: ["resultsColumns", { insightId }],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/gold/views/${insightId}/columns`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch results columns for gold view ${insightId}`,
        );
      }

      const { data } = ColumnsResultParser.parse(await response.json());
      return data;
    },
  });
};

const toSearchParams = ({
  cursor,
  filters,
  sorting,
  limit,
}: {
  cursor?: number;
  filters?: TableFilters;
  sorting?: SortingState;
  limit?: number;
}): URLSearchParams => {
  const params: Record<string, string> = {};

  if (limit !== undefined) {
    params.limit = String(limit);
  }

  if (cursor !== undefined) {
    params.cursor = String(cursor);
  }

  const sortingParams = sorting
    ?.map((c) => `${c.id}:${c.desc ? "DESC" : "ASC"}`)
    .join(",");

  if (sortingParams) {
    params.order = sortingParams;
  }

  if (filters) {
    const filterParams = Object.entries(filters)
      .flatMap(([key, value]) => {
        if (
          !(
            typeof value === "string" ||
            typeof value === "boolean" ||
            typeof value === "number"
          )
        )
          return [];
        return `${key}:${encodeURIComponent(value)}`;
      })
      .join(",");

    if (filterParams.length > 0) {
      params.filter = filterParams;
    }
  }

  return new URLSearchParams(params);
};

const useResultsData = (
  insightId: InsightDb["id"],
  {
    sorting,
    filters,
    pageSize = 20,
  }: { sorting: SortingState; filters: TableFilters; pageSize?: number },
) => {
  return useInfiniteQuery({
    queryKey: ["resultsData", { insightId }, { pageSize, sorting, filters }],
    staleTime: Infinity,
    queryFn: async ({ pageParam = 0 }) => {
      const cursor = pageParam * pageSize;
      const limit = pageSize;
      const params = toSearchParams({ cursor, limit, sorting, filters });
      const response = await fetchAPIWithToken(
        `/api/gold/views/${insightId}/data?${params.toString()}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data for gold view ${insightId}`);
      }

      const { data } = DataResultParser.parse(await response.json());
      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length < pageSize ? null : allPages.length;
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });
};

const useArtifactData = (
  artifactId: ArtifactDb["id"],
  {
    sorting,
    filters,
    pageSize = 50,
  }: { sorting: SortingState; filters: TableFilters; pageSize?: number },
) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ["resultsData", { artifactId }, { pageSize, sorting, filters }],
    staleTime: Infinity,
    queryFn: async ({ pageParam = 0 }) => {
      const cursor = pageParam * pageSize;
      const limit = pageSize;
      const params = toSearchParams({ cursor, limit, sorting, filters });
      const response = await fetchAPIWithToken(
        `/api/artifacts/${artifactId}/data?${params.toString()}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data for artifact ${artifactId}`);
      }

      const data: ArtifactTableData = ArtifactTableDataSchema.parse(
        await response.json(),
      );

      /*
       * Because we do not materialize the view in the backend database for artifact
       * if a query does not return any result (because of filtering for instance)
       * we don't even have the columns' name to show the table
       * We are leveraging the staleTime from react-query to fallback to
       * previous queries that had headers in this situation
       */
      if (data.length === 0) {
        // Get data from any previous query with same artifactId
        const previousData = queryClient.getQueriesData({
          queryKey: ["resultsData", { artifactId }],
        });

        for (const [, queryData] of previousData) {
          if (
            typeof queryData === "object" &&
            queryData &&
            "pages" in queryData &&
            Array.isArray(queryData.pages)
          ) {
            const columns = queryData.pages[0]?.columns;
            if (columns?.length > 0) {
              console.log(queryData);
              return { data: [], columns: columns };
            }
          }
        }
      }

      const columns = [...new Set(data.flatMap(Object.keys))].map((v) => ({
        name: v,
      }));
      return { data, columns };
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.data.length < pageSize ? null : allPages.length;
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });
};

const ArtifactPreview = ({ artifactId }: { artifactId: number }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<TableFilters>({});

  const {
    data: dataPages,
    fetchNextPage,
    isFetchingNextPage,
    isPending: isPendingData,
  } = useArtifactData(artifactId, { sorting, filters });

  const data = useMemo(
    () => dataPages?.pages.flatMap(({ data }) => data),
    [dataPages],
  );
  const headers = useMemo(() => dataPages?.pages.at(-1)?.columns, [dataPages]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 10px of the bottom of the table
        if (
          scrollHeight - scrollTop - clientHeight < 10 &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetchingNextPage],
  );

  return (
    <Stack
      flex={1}
      style={{
        overflow: "hidden",
      }}
    >
      {isPendingData ? (
        <Center flex={1}>
          <Loader />
        </Center>
      ) : (
        <>
          <Table
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            data={data}
            dataColumns={headers}
            onSortingChange={setSorting}
            onFiltersChange={setFilters}
            emptyChildren={<EmptyArtifactTable />}
          />
          {isFetchingNextPage && (
            <Center py={"lg"}>
              <Loader />
            </Center>
          )}
        </>
      )}
    </Stack>
  );
};

const InsightTableDataPreview = ({ insight }: { insight: InsightDb }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<TableFilters>({});

  const { data: dataColumns, isPending: isPendingColumns } = useResultsColumns(
    insight.id,
  );

  const {
    data: dataPages,
    fetchNextPage,
    isFetchingNextPage,
    isPending: isPendingData,
  } = useResultsData(insight.id, { sorting, filters });

  const data = useMemo(() => dataPages?.pages.flat(), [dataPages]);
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 100px of the bottom of the table
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetchingNextPage],
  );

  return (
    <Stack
      flex={1}
      style={{
        overflow: "hidden",
      }}
    >
      {isPendingColumns || isPendingData ? (
        <Center flex={1}>
          <Loader />
        </Center>
      ) : (
        <>
          <Table
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            data={data}
            dataColumns={dataColumns}
            onSortingChange={setSorting}
            onFiltersChange={setFilters}
            emptyChildren={<EmptyReportTable insight={insight} />}
          />
          {isFetchingNextPage && (
            <Center py={"lg"}>
              <Loader />
            </Center>
          )}
        </>
      )}
    </Stack>
  );
};

export const DataPreview = ({ insight }: { insight: InsightDb }) => {
  const artifactId = useArtifactIdParam();
  const { data: artifact, isPending } = useArtifact(artifactId);
  const [activeTab, setActiveTab] = useState<string | null>("data");

  const isLoading = !!artifactId && isPending;

  /*
   * There are few scenario where it does not make sense to restart
   * 1. We only have a report and no artifacts
   * 2. We are the last artifact
   */
  const canRestart = (() => {
    const allArtifacts = insight.messages.flatMap(({ artifacts }) => artifacts);

    if (artifactId == null && allArtifacts.length == 0) return false;
    else if (allArtifacts.at(-1)?.id == artifactId) return false;
    else return true;
  })();

  return (
    <Stack flex={1} style={{ overflow: "hidden" }} mr={"xl"}>
      <ArtifactsList insight={insight} />
      {!isLoading && (
        <Tabs
          classNames={classes}
          variant="outline"
          value={activeTab}
          onChange={setActiveTab}
        >
          <Tabs.List>
            <Tabs.Tab value="data">
              <Text maw={350} truncate="end" size="sm">
                {artifact?.name ?? insight.report?.name}
              </Text>
            </Tabs.Tab>
            <Tabs.Tab disabled={!artifactId} value="verification">
              <Text size="sm">Verify results</Text>
            </Tabs.Tab>
            {canRestart && (
              <RestartModal insight={insight} artifact={artifact}>
                <Group
                  gap={8}
                  wrap="nowrap"
                  style={{
                    cursor: "pointer",
                    userSelect: "none",
                    alignSelf: "center",
                  }}
                  ml="auto"
                >
                  <BiReset size={14} color="var(--mantine-color-dimmed)" />
                  <Text size="sm" c={"dimmed"} style={{ whiteSpace: "nowrap" }}>
                    restart from here
                  </Text>
                </Group>
              </RestartModal>
            )}
          </Tabs.List>
          <Card
            withBorder
            flex={1}
            p={0}
            style={{
              overflow: "hidden",
              borderTop: "0",
              borderBottom: "2px solid var(--mantine-primary-color-6)",
              borderLeft: "2px solid var(--mantine-primary-color-6)",
              borderRight: "2px solid var(--mantine-primary-color-6)",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
            }}
            radius={"md"}
          >
            <Tabs.Panel value="data">
              {artifactId ? (
                <ArtifactPreview artifactId={artifactId} />
              ) : (
                <InsightTableDataPreview insight={insight} />
              )}
            </Tabs.Panel>
            <Tabs.Panel value="verification">
              {artifactId ? (
                <ResultsVerification artifactId={artifactId} />
              ) : (
                "Not available for report."
              )}
            </Tabs.Panel>
          </Card>
        </Tabs>
      )}
    </Stack>
  );
};
