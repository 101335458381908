import { Auth as AuthLayout } from "@layout/Auth";
import { Main as MainLayout } from "@layout/Main";

import { ErrorPage } from "@pages/ErrorPage";
import { Home } from "@pages/Home";
import { InsightsList } from "@pages/InsightsList";
import { Login } from "@pages/Login";
import { ReportBuilder } from "@pages/ReportBuilder";
import { Settings } from "@pages/Settings";

import { useClient } from "@hooks/use-client";
import { InsightBuilder } from "@pages/InsightBuilder";
import { ReportsList } from "@pages/ReportsList";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createBrowserRouter, useRouteError } from "react-router-dom";

const SentryRouteErrorFallback = () => {
  const { supabase } = useClient();
  const error = useRouteError() as Error;

  useEffect(() => {
    const sendError = async () => {
      const session = await supabase.auth.getSession();

      Sentry.withScope((scope) => {
        scope.setUser({
          id: session.data.session?.user.id,
          email: session.data.session?.user.email,
        });

        Sentry.captureException(error);
      });
    };

    sendError().catch((e) =>
      console.warn(`unable to send data to sentry, ${e}`),
    );
  }, [error, supabase]);

  return null;
};

export const router: ReturnType<typeof createBrowserRouter> =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
    {
      path: "/login",
      element: <AuthLayout />,
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "/login/pin",
          element: <Login usePINCode={true} />,
        },
      ],
    },
    {
      element: <MainLayout />,
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/report",
          element: <ReportsList />,
        },
        {
          path: "/report/:reportId",
          element: <ReportBuilder />,
        },
        {
          path: "/insight",
          element: <InsightsList />,
        },
        {
          path: "/insight/:insightId",
          element: <InsightBuilder />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/error",
          element: <ErrorPage />,
        },
      ],
    },
  ]);
