import { fetchAPIWithToken } from "@hooks/use-client";
import type { ArtifactDb } from "@mm/shared/schemas/insights";
import { useQuery } from "@tanstack/react-query";

const queryKeyForArtifact = (artifactId: number) => {
  return ["artifacts", { artifactId }];
};

export const useArtifact = (artifactId: number | null) => {
  return useQuery({
    enabled: !!artifactId,
    queryKey: [...queryKeyForArtifact(Number(artifactId))],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await fetchAPIWithToken(`/api/artifacts/${artifactId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Unable to get artifact id: ${artifactId}`);
      }

      return (await response.json()) as ArtifactDb;
    },
  });
};
