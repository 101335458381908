import { Image, Stack, Text } from "@mantine/core";
import type { InsightRecordWithArtifacts } from "@mm/shared/schemas/insights";
import React, { useCallback } from "react";
import classes from "./Table.module.css";

import { ChatContainer } from "@components/Chat/ChatContainer";
import { UserMessage } from "@components/Chat/Messages/UserMessage";
import { WaitingMessage } from "@components/Chat/Messages/WaitingMessage";
import EmptyMessagesImage from "@images/empty-messages.svg";
import { ArtifactMessage, useArtifactIdParam } from "./ArtifactMessage";
import { AssistantMessage } from "./AssistantMessage";

const messageComponents = {
  user: UserMessage,
  assistant: AssistantMessage,
  artifact: ArtifactMessage,
  waiting: WaitingMessage,
};

const EmptyState = () => (
  <Stack style={{ textAlign: "center" }} gap="xl">
    <Image m="auto" w={125} alt="Empty messages" src={EmptyMessagesImage} />
    <Text c="dimmed">
      You haven't created any insights yet. <br />
      <br />
      What do you want to do?
    </Text>
  </Stack>
);

interface MessageProps {
  messages: InsightRecordWithArtifacts[];
}

export const Messages: React.FC<MessageProps> = ({ messages }) => {
  const currentArtifactId = useArtifactIdParam();

  const handleMessagesRendered = useCallback(
    (containerRef: React.RefObject<HTMLDivElement>) => {
      if (!currentArtifactId || !containerRef.current) return;

      const timeout = setTimeout(() => {
        containerRef.current
          ?.querySelector(`[data-message-artifact="${currentArtifactId}"]`)
          ?.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }, 100);

      return () => clearTimeout(timeout);
    },
    [currentArtifactId],
  );

  return (
    <ChatContainer
      messages={messages}
      messageComponents={messageComponents}
      emptyState={<EmptyState />}
      onMessagesRendered={handleMessagesRendered}
      scrollAreaProps={{
        flex: 1,
        scrollbars: "y",
        offsetScrollbars: true,
        classNames: classes,
      }}
    />
  );
};
