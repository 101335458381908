import { fetchAPIWithToken } from "@hooks/use-client";
import {
  ArtifactSuggestionsSchema,
  type ArtifactDb,
  type InsightDb,
  type InsightList,
  type InsightResponse,
} from "@mm/shared/schemas/insights";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";

export const queryKeyForInsight = (insightId: number) => {
  return ["insights", { insightId }];
};

/*
 * This can seem superficial but is actually really useful when
 * dealing with queryClient.{set,get}QueryData with typescript.
 *
 * Because we are typing the queryFn properly here, the return
 * type of the {set,get}QueryData will be properly typed as well
 * @param insightId
 */
export const insightOption = (insightId: number) => {
  return queryOptions({
    queryKey: queryKeyForInsight(insightId),
    queryFn: async () => {
      const response = await fetchAPIWithToken(`/api/insights/${insightId}`);

      if (!response.ok) {
        throw new Error(`Unable to retrieve insight with id: ${insightId}`);
      }

      return (await response.json()) as InsightResponse;
    },
    refetchInterval: ({ state: { data } }) => {
      // refetch the query every second if the status is pending
      if (!data || data.status === "pending") return 1000;
      return false;
    },
  });
};

export const useInsight = ({ insightId }: { insightId: number }) => {
  return useQuery(insightOption(insightId));
};

export const useInsightRestart = () => {
  return useMutation({
    mutationFn: async ({
      insight,
      artifact,
    }: {
      insight: InsightDb;
      artifact?: ArtifactDb;
    }) => {
      const response = await fetchAPIWithToken(
        artifact
          ? `/api/insights/${artifact.gold_view_id}/restart/${artifact.id}`
          : `/api/insights/${insight.id}/restart`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error("Unable to restart insight thread");
      }

      return (await response.json()) as InsightResponse;
    },
  });
};

export const useSuggestions = (insightId: number, messagesLength: number) => {
  return useQuery({
    queryKey: [
      ...queryKeyForInsight(insightId),
      "suggestions",
      { messagesLength },
    ],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/insights/${insightId}/suggestions`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Unable to generate suggestions for insight: ${insightId}`,
        );
      }

      return ArtifactSuggestionsSchema.parse(await response.json());
    },
  });
};

export const useInsightsList = () => {
  return useQuery({
    queryKey: ["insights"],
    queryFn: async () => {
      const response = await fetchAPIWithToken(`/api/insights`);

      if (!response.ok) {
        throw new Error(`Unable to retrieve list of insights`);
      }

      return (await response.json()) as InsightList;
    },
  });
};
