import { useInsight } from "@api/useInsight";
import { Page } from "@layout/Page";
import { Group, Mark, Text } from "@mantine/core";
import type { InsightDb } from "@mm/shared/schemas/insights";
import React from "react";
import { useParams } from "react-router-dom";

import { Insight } from "@components/InsightBuilder/Insight";
import { Onboarding } from "../components/InsightBuilder/Onboarding";
import { Steps } from "./Steps";

const InsightBuilderContent: React.FC<{
  insight: InsightDb;
}> = ({ insight }) => {
  return (
    <Page
      fullWidth
      title={
        <Text size="xl" fw={"900"}>
          Building insights from{" "}
          <Mark
            fz={"lg"}
            fw={"normal"}
            ff={"monospace"}
            bg={"gray.2"}
            ml={6}
            px={4}
            py={4}
            color="gray"
            style={{ borderRadius: "var(--mantine-radius-md)" }}
          >
            {insight.report?.name}
          </Mark>
        </Text>
      }
      rightSection={
        <Steps active={3} reportLink={`/report/${insight.report?.id}`} />
      }
    >
      <Group
        gap={"xl"}
        flex={1}
        align="stretch"
        wrap="nowrap"
        style={{ scrollBehavior: "smooth", overflow: "hidden" }}
      >
        {insight.onboarded ? (
          <Insight insight={insight} />
        ) : (
          <Onboarding insight={insight} />
        )}
      </Group>
    </Page>
  );
};

export const InsightBuilder = () => {
  const params = useParams<{ insightId: string }>();
  const insightId = Number(params.insightId);

  const { data: insightResponse } = useInsight({ insightId });

  if (!insightResponse) return <Page fullWidth>{null}</Page>;

  return <InsightBuilderContent insight={insightResponse.insight} />;
};
