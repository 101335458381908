import { Button, Center, Loader } from "@mantine/core";

import { queryKeyForInsight } from "@api/useInsight";
import { useClient } from "@hooks/use-client";
import { notifications } from "@mantine/notifications";
import { FINAL } from "@mm/shared/schemas/gold";
import type { InsightDb } from "@mm/shared/schemas/insights";
import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { BiCheck } from "react-icons/bi";
import { MotionBadge, MotionStack, MotionText } from "../../motion/elements";
import { usePostMessage } from "./hooks/postMessage";

const fadeInUpVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
      delayChildren: 0.1,
    },
  },
};

const Waiting = ({ insight }: { insight: InsightDb }) => {
  return (
    <Center flex={1}>
      <AnimatePresence>
        <MotionStack
          key="report-status"
          variants={containerVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          pt={64}
          h={"100%"}
          align="center"
          gap={"xl"}
        >
          <MotionBadge
            key="generating-badge"
            variants={fadeInUpVariants}
            color="cyan"
            tt={"none"}
            fw={"normal"}
            radius={"md"}
            size="xl"
            variant="light"
            leftSection={<Loader size={"xs"} mr={"xs"} />}
          >
            Preparing your data&hellip;
          </MotionBadge>
          <MotionText
            key="generating-text"
            variants={fadeInUpVariants}
            size="xl"
            fw={"900"}
          >
            Then, here is what we'll do together:
          </MotionText>
          <MotionText
            variants={fadeInUpVariants}
            lh={1.8}
            ta={"center"}
            w={450}
          >
            {insight.report?.analysis_plan}
          </MotionText>
        </MotionStack>
      </AnimatePresence>
    </Center>
  );
};
export const Onboarding = ({ insight }: { insight: InsightDb }) => {
  const { mutate: postMessage, isPending } = usePostMessage();
  const queryClient = useQueryClient();
  const { supabase } = useClient();

  const endOnboarding = async (insight: InsightDb) => {
    await supabase
      .from("gold_views")
      .update({ onboarded: true })
      .eq("id", insight.id);

    queryClient.invalidateQueries({
      queryKey: queryKeyForInsight(insight.id),
    });
  };

  const analyzeThis = () => {
    postMessage(
      { insightId: insight.id, message: insight.report!.analysis_plan! },
      {
        onSuccess: async () => {
          await endOnboarding(insight);
        },
        onError: async () => {
          notifications.show({
            title: "Unable to analyze",
            message:
              "We were not able to perform the analysis. Please try again or start to analyze something else",
            color: "red",
          });
        },
      },
    );
  };

  const analyzeElse = async () => {
    await endOnboarding(insight);
  };

  // @todo: deal with error
  if (insight.status !== FINAL) return <Waiting insight={insight} />;

  return (
    <Center flex={1}>
      <AnimatePresence>
        <MotionStack
          key="report-status"
          variants={containerVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          pt={64}
          h={"100%"}
          align="center"
          gap={"xl"}
        >
          <MotionBadge
            key="done-badge"
            variants={fadeInUpVariants}
            color="green"
            tt={"none"}
            fw={"normal"}
            radius={"md"}
            size="xl"
            variant="light"
            leftSection={<BiCheck size={24} />}
          >
            Done preparing your data
          </MotionBadge>
          <MotionText
            key="done-text"
            variants={fadeInUpVariants}
            size="xl"
            fw={"900"}
          >
            Now, here is what we planned:
          </MotionText>
          <MotionText
            variants={fadeInUpVariants}
            lh={1.8}
            ta={"center"}
            w={450}
          >
            {insight.report?.analysis_plan}
          </MotionText>
          <MotionStack gap={"xs"} variants={fadeInUpVariants}>
            <Button loading={isPending} onClick={analyzeThis}>
              Let's analyze that
            </Button>
            <Button size="sm" variant="transparent" onClick={analyzeElse}>
              analyze something else
            </Button>
          </MotionStack>
        </MotionStack>
      </AnimatePresence>
    </Center>
  );
};
