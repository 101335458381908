import { Box, Card, Divider, Group, Stack, Text } from "@mantine/core";
import {
  LLMResponseWithReportSuggestions,
  type LLMReportSuggestionsType,
} from "@mm/shared/companion/types";
import React from "react";
import { BiAnalyse } from "react-icons/bi";
import { useReportBuilder } from "../ReportBuilderContext";

import { MessageContent } from "@components/Chat/Messages/MessageContent";
import classes from "./AssistantMessage.module.css";

const Suggestions = ({
  suggestions,
}: {
  suggestions: LLMReportSuggestionsType["suggestions"];
}) => {
  const { postMessage } = useReportBuilder();

  return suggestions.map((suggestion, index) => {
    return (
      <React.Fragment key={`${suggestion.name}_${suggestion.explanation}`}>
        <Group
          gap="xs"
          py={"md"}
          px={"md"}
          className={classes.suggestion}
          style={{ overflow: "hidden", cursor: "pointer" }}
          onClick={() =>
            postMessage(
              `Add column "${suggestion.name}":\n${suggestion.explanation}`,
            )
          }
        >
          <Stack style={{ alignSelf: "flex-start" }}>
            <Text size="sm" fw={500}>
              {index + 1}.
            </Text>
          </Stack>
          <Stack flex={1} gap={2}>
            <Text size="sm">{suggestion.name}</Text>
            <Text size="sm" c="dimmed">
              {suggestion.explanation}
            </Text>
          </Stack>
          <Group gap={4} className={classes.hidden}>
            <BiAnalyse color="var(--mantine-primary-color-6)" />
            <Text size="sm" c="var(--mantine-primary-color-6)">
              apply
            </Text>
          </Group>
        </Group>
        {suggestions.length > 1 && index !== suggestions.length - 1 ? (
          <Divider color="var(--mantine-primary-color-6)" size={"2px"} />
        ) : null}
      </React.Fragment>
    );
  });
};

export const AssistantMessage = ({ content }: { content: string }) => {
  const { text: message, suggestions } = LLMResponseWithReportSuggestions.parse(
    JSON.parse(content),
  );

  return (
    <>
      <Stack style={{ overflow: "hidden" }} gap={0}>
        <Box
          bg={"gray.8"}
          px={"md"}
          py={2}
          style={{
            alignSelf: "flex-start",
            borderTopLeftRadius: "var(--mantine-radius-sm)",
            borderTopRightRadius: "var(--mantine-radius-sm)",
          }}
        >
          <Text size="sm" c={"white"}>
            Assistant
          </Text>
        </Box>
        <Card
          miw={150}
          shadow="none"
          bg={"gray.0"}
          style={{
            overflow: "hidden",
            border: "2px solid var(--mantine-color-gray-8)",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "var(--mantine-radius-md)",
            borderBottomRightRadius: "var(--mantine-radius-md)",
            borderBottomLeftRadius: "var(--mantine-radius-md)",
          }}
        >
          <MessageContent>{message}</MessageContent>
        </Card>
      </Stack>
      {suggestions.length > 0 && (
        <Stack style={{ overflow: "hidden" }} gap={0}>
          <Box
            bg={"var(--mantine-primary-color-6)"}
            px={"md"}
            py={2}
            style={{
              alignSelf: "flex-start",
              borderTopLeftRadius: "var(--mantine-radius-sm)",
              borderTopRightRadius: "var(--mantine-radius-sm)",
            }}
          >
            <Text size="sm" c={"white"}>
              Some suggestions
            </Text>
          </Box>
          <Card
            p={0}
            shadow="none"
            bg={"gray.0"}
            style={{
              overflow: "hidden",
              border: "2px solid var(--mantine-primary-color-6)",
              borderTopLeftRadius: "0",
              borderTopRightRadius: "var(--mantine-radius-md)",
              borderBottomRightRadius: "var(--mantine-radius-md)",
              borderBottomLeftRadius: "var(--mantine-radius-md)",
            }}
          >
            <Suggestions suggestions={suggestions} />
          </Card>
        </Stack>
      )}
    </>
  );
};
