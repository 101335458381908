import { ChatContainer } from "@components/Chat/ChatContainer";
import { ToolMessage } from "@components/Chat/Messages/ToolMessage";
import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Input,
  Stack,
  Transition,
} from "@mantine/core";
import { getHotkeyHandler } from "@mantine/hooks";
import React, { useCallback, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { UserMessage } from "../../Chat/Messages/UserMessage";
import { WaitingMessage } from "../../Chat/Messages/WaitingMessage";
import { useReportBuilder } from "../ReportBuilderContext";
import { AssistantMessage } from "./AssistantMessage";
import styles from "./ChatButton.module.css";
import classes from "./Messages.module.css";

type ChatProps = {
  opened: boolean | undefined;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const messageComponents = {
  user: UserMessage,
  assistant: AssistantMessage,
  tool: ToolMessage,
  waiting: WaitingMessage,
};

export const Chat: React.FC<ChatProps> = ({ opened, setOpened }) => {
  const { postMessage, isPosting, report, isPending } = useReportBuilder();
  const [value, setValue] = useState("");

  const focus = useCallback(
    (node: HTMLInputElement | null) => {
      if (node && opened && !isPosting) {
        // TODO: Not sure why focus on its own doesn't work, ugly render loop hack
        setTimeout(() => node.focus(), 10);
      }
    },
    [opened, isPosting],
  );

  const handleSubmit = async () => {
    if (value) {
      try {
        postMessage(value);
        setValue("");
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <Group flex={0} gap={"xs"}>
      <Transition
        mounted={opened ?? false}
        transition="slide-right"
        duration={200}
        timingFunction="ease"
        keepMounted
      >
        {(styles) => (
          <Stack
            pt={20}
            style={styles}
            flex={1}
            w={"30rem"}
            align="stretch"
            h={"100%"}
            justify="space-between"
          >
            <ChatContainer
              messages={report?.messages ?? []}
              loading={!report || isPending}
              messageComponents={messageComponents}
              scrollAreaProps={{
                scrollbars: "y",
                offsetScrollbars: true,
                classNames: classes,
              }}
            />
            <Input
              ref={focus}
              disabled={isPosting}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              size="md"
              rightSectionPointerEvents="all"
              rightSectionWidth={75}
              rightSection={
                <Button
                  disabled={isPosting}
                  variant="subtle"
                  size="xs"
                  onClick={handleSubmit}
                >
                  Reply
                </Button>
              }
              onKeyDown={getHotkeyHandler([
                ["mod+K", () => setOpened(!opened)],
                ["Enter", handleSubmit],
              ])}
            />
          </Stack>
        )}
      </Transition>
      <Stack h={"80%"} flex={0} justify="center">
        <Divider mx={"auto"} flex={1} orientation="vertical" />
        <ActionIcon
          className={styles.button}
          flex={0}
          variant="subtle"
          onClick={() => setOpened(!opened)}
          disabled={opened === undefined}
        >
          {opened ? <BiChevronLeft size={24} /> : <BiChevronRight size={24} />}
        </ActionIcon>
        <Divider mx={"auto"} flex={1} orientation="vertical" />
      </Stack>
    </Group>
  );
};
