import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";

const SENTRY_ENV =
  (import.meta.env.VITE_SENTRY_ENVIRONMENT as string | undefined) ??
  "development";

const replaysSampleRate = SENTRY_ENV == "production" ? 1.0 : 0.4;

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string | undefined,
  environment: SENTRY_ENV,
  integrations: [
    Sentry.captureConsoleIntegration({ levels: ["error"] }),
    Sentry.httpClientIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/nqkvhuybwzfrnjblzlzi\.supabase\.co/,
    /^https:\/\/companion-backend\.magemetrics\.com/,
    /^https:\/\/rzakwddammmlzauzoeli\.supabase\.co/,
    /^https:\/\/companion-backend-staging\.magemetrics\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%
  replaysOnErrorSampleRate: replaysSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
