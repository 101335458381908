import { fetchAPIWithToken } from "@hooks/use-client";
import { Center, Loader, Stack, Tabs, Text } from "@mantine/core";
import {
  LLMVerifyResultsResponse,
  type LLMVerifyResultsResponseType,
} from "@mm/shared/companion/types";
import { useQuery } from "@tanstack/react-query";
import { CodeAnalysis } from "./CodeAnalysis";
import { FlowAnalysis } from "./FlowAnalysis";
import { GeneralAnalysis } from "./GeneralAnalysis";

const useVerifyResults = (artifactId: number) => {
  return useQuery({
    queryKey: ["verifyResults", artifactId],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/artifacts/${artifactId}/verify`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      return LLMVerifyResultsResponse.parse(await response.json());
    },
  });
};

const VerificationTabs = ({ data }: { data: LLMVerifyResultsResponseType }) => {
  return (
    <Tabs
      flex={1}
      defaultValue="General Analysis"
      variant="default"
      display={"flex"}
      style={{ flexDirection: "column", overflow: "scroll" }}
      px={"md"}
    >
      <Tabs.List my={"sm"}>
        <Tabs.Tab value="General Analysis">General Analysis</Tabs.Tab>
        <Tabs.Tab value="Flow Analysis">Flow Analysis</Tabs.Tab>
        <Tabs.Tab value="Code Analysis">Code Analysis</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel flex={1} p={"sm"} value="General Analysis">
        <GeneralAnalysis generalAnalysis={data.generalAnalysis} />
      </Tabs.Panel>
      <Tabs.Panel flex={1} p={"sm"} value="Flow Analysis">
        <FlowAnalysis flowAnalysis={data.flowAnalysis} />
      </Tabs.Panel>
      <Tabs.Panel flex={1} p={"sm"} value="Code Analysis">
        <CodeAnalysis codeAnalysis={data.codeAnalysis} />
      </Tabs.Panel>
    </Tabs>
  );
};

export const ResultsVerification = ({ artifactId }: { artifactId: number }) => {
  const { data, isPending } = useVerifyResults(artifactId);

  return isPending || !data ? (
    <Center flex={1}>
      <Stack align="center">
        <Loader type="dots" />
        <Text c={"dimmed"} size="sm">
          Analyzing your results&hellip;
        </Text>
      </Stack>
    </Center>
  ) : (
    <VerificationTabs data={data} />
  );
};
