import { insightOption, queryKeyForInsight } from "@api/useInsight";
import { fetchAPIWithToken } from "@hooks/use-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ChatCompletionMessageParam } from "openai/resources/index.mjs";

export const usePostMessage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      insightId,
      message,
    }: {
      insightId: number;
      message: string;
    }) => {
      const response = await fetchAPIWithToken(
        `/api/insights/${insightId}/chat`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_message: message }),
        },
      );

      if (!response.ok) {
        console.error(`Failed to add message to insight ${insightId}`);
        throw new Error(`Failed to add message to insight ${insightId}`);
      }

      return await response.json();
    },
    onMutate: async ({ insightId, message }) => {
      // Cancel any outgoing refetches
      const queryKey = queryKeyForInsight(insightId);
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const cacheData = queryClient.getQueryData(
        insightOption(insightId).queryKey,
      );

      if (!cacheData) {
        return { previousResponse: undefined };
      }

      const previousResponse = cacheData;
      //
      // Optimistically update to the new value
      const now = new Date().toISOString();
      queryClient.setQueryData(insightOption(insightId).queryKey, {
        status: previousResponse.status,
        insight: {
          ...previousResponse.insight,
          messages: [
            ...previousResponse.insight.messages,
            // Optimistically add user and waiting messages matching the API response structure
            // This specific structure is required for the messages to pass the filter in Messages.tsx:
            {
              display_role: "user",
              id: -2, // temporary id for user message
              created_at: now,
              gold_view_id: insightId,
              artifacts: [],
              message: {
                role: "user",
                content: [
                  {
                    text: message,
                    type: "text",
                  },
                ],
              } satisfies ChatCompletionMessageParam,
            },
            {
              display_role: "waiting",
              id: -1, // temporary id for waiting message
              created_at: new Date().toISOString(),
              gold_view_id: insightId,
              artifacts: [],
              message: {
                role: "assistant",
                content: [
                  {
                    text: "",
                    type: "text",
                  },
                ],
              } satisfies ChatCompletionMessageParam,
            },
          ],
        },
      });

      // Return a context object with the snapshotted value
      return { previousResponse };
    },
    onError: (_err, { insightId }, context) => {
      if (context?.previousResponse) {
        queryClient.setQueryData(
          queryKeyForInsight(insightId),
          context.previousResponse,
        );
      }
    },
    onSettled: async (_data, _err, { insightId }) => {
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: queryKeyForInsight(insightId),
        }),
      ]);
    },
  });
};
