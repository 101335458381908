import dayjs from "dayjs";
import { z, type ZodType } from "zod";

export const coerceNullToUndefined = <Type>(
  value: Type | null | undefined,
): Type | undefined => {
  return value ?? undefined;
};

export const orUndef = <T extends ZodType>(t: T) => {
  return t.nullish().transform(coerceNullToUndefined);
};

export const ISODate = () => {
  return z.string().refine((str) => dayjs(str).isValid(), {
    message: "Not a valid ISO string date ",
  });
};

// Snippet from https://zod.dev/?id=json-type
export const JsonSchema = (() => {
  const literalSchema = z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.null(),
  ]);
  type Literal = z.infer<typeof literalSchema>;
  type Json = Literal | { [key: string]: Json } | Json[];
  const jsonSchema: z.ZodType<Json> = z.lazy(() =>
    z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
  );
  return jsonSchema;
})();

export const stringToJSONSchema = z.string().transform((str, ctx) => {
  try {
    return JSON.parse(str) as unknown;
  } catch {
    ctx.addIssue({ code: "custom", message: "Invalid JSON" });
    return z.NEVER;
  }
});

export const jsonArraySchema = stringToJSONSchema.pipe(
  z.object({}).passthrough().array(),
);
