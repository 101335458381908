import {
  Text,
  Stack,
  type TextProps,
  Badge,
  type StackProps,
  type BadgeProps,
} from "@mantine/core";
import { motion, type MotionProps } from "framer-motion";
import { forwardRef, type ElementRef } from "react";

/* We have an issue with framer-motion for typing.
 * Let's wait for the release of new version of framer-motion (now motion) before
 * spending more time on it.
 * Those complexe types are copied from framer source code
 */
type MotionComponentProps<Props> = {
  [K in Exclude<keyof Props, keyof MotionProps>]?: Props[K];
} & MotionProps;

type CustomMotion<T> = React.ForwardRefExoticComponent<
  MotionComponentProps<React.PropsWithChildren<T>>
>;

export const MotionText: CustomMotion<
  TextProps & React.RefAttributes<HTMLParagraphElement>
> = motion.create(
  forwardRef<ElementRef<"p">, TextProps>((props, ref) => (
    <Text ref={ref} {...props} />
  )),
);

export const MotionStack: CustomMotion<
  StackProps & React.RefAttributes<HTMLDivElement>
> = motion.create(
  forwardRef<ElementRef<"div">, StackProps>((props, ref) => (
    <Stack ref={ref} {...props} />
  )),
);

export const MotionBadge: CustomMotion<
  BadgeProps & React.RefAttributes<HTMLDivElement>
> = motion.create(
  forwardRef<ElementRef<"div">, BadgeProps>((props, ref) => (
    <Badge ref={ref} {...props} />
  )),
);
