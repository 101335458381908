import { useNavigate } from "react-router-dom";

import { ActionIcon, Group, Stack, Tooltip } from "@mantine/core";

import { BiMenuAltLeft } from "react-icons/bi";

interface PageProps {
  fullWidth?: boolean;
  title?: React.ReactNode;
  rightSection?: React.ReactNode;
  children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({
  fullWidth = false,
  title,
  rightSection,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <Stack w={fullWidth ? "100%" : "90%"} py={10} h={"100vh"}>
      <Group
        h={"xl"}
        /* Header width must be 90% when the full page is fullWidth, so it remains unchanged no matter if fullWidth or not */
        w={fullWidth ? "90%" : "100%"}
        mx={"auto"}
        justify="space-between"
        align="center"
      >
        <Group gap={"xs"} align="center">
          <Tooltip label="Show menu" offset={8}>
            <ActionIcon
              onClick={() => navigate({ hash: "menu" })}
              variant={"subtle"}
              size={"md"}
            >
              <BiMenuAltLeft size={20} />
            </ActionIcon>
          </Tooltip>
          {title}
        </Group>
        {rightSection}
      </Group>
      {children}
    </Stack>
  );
};
