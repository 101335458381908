import { Switch } from "@mantine/core";
import { useSubscription } from "./useSubscription";

interface ChannelNotificationSwitchProps {
  channelType: string;
  label: string;
  disabled?: boolean;
}

export const ChannelNotificationSwitch = ({
  channelType,
  label,
  disabled = false,
}: ChannelNotificationSwitchProps) => {
  const {
    preferences,
    toggleSubscription,
    isTogglingSubscription,
    isSubscribed,
  } = useSubscription();

  const channelSubscribed = preferences?.channel_types?.[channelType] ?? false;

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleSubscription({
      channel_type: channelType,
      subscribed: event.currentTarget.checked,
    });
  };

  return (
    <Switch
      size="md"
      label={label}
      checked={channelSubscribed}
      onChange={handleToggle}
      disabled={disabled || !isSubscribed || isTogglingSubscription}
    />
  );
};
