import { Recommendation } from "./Recommendation";

import { fetchAPIWithToken } from "@hooks/use-client";
import { Carousel, type Embla } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { ActionIcon, ActionIconGroup, Group, Stack, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import {
  LLMRecommendationsSchema,
  type LLMRecommendationsSchemaType,
} from "@mm/shared/schemas/recommendations";
import { z } from "zod";

const getNewRecommendations = async () => {
  const response = await fetchAPIWithToken(`/api/recommendations?count=9`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  const { data, error } = z
    .object({
      recommendations: LLMRecommendationsSchema.array(),
    })
    .safeParse(await response.json());

  if (error) {
    console.error("Unable to parse recommendations");
    console.error(error);
    return [];
  }

  return data.recommendations;
};

export const Recommendations = ({
  createReport,
}: {
  createReport: (starter: string) => Promise<void>;
}) => {
  const [embla, setEmbla] = useState<Embla | null>(null);

  const { data: currentRecommendations } = useQuery<
    LLMRecommendationsSchemaType[],
    Error
  >({
    queryKey: ["recommendations", fetchAPIWithToken],
    queryFn: getNewRecommendations,
  });

  return (
    <Stack gap={"sm"} w={"100%"}>
      <Group justify="space-between">
        <Text c={"dimmed"} size="sm">
          Some recommendations based on your data
        </Text>
        <ActionIconGroup>
          <ActionIcon onClick={() => embla?.scrollPrev()} variant="subtle">
            <BiLeftArrowAlt size={20} />
          </ActionIcon>
          <ActionIcon onClick={() => embla?.scrollNext()} variant="subtle">
            <BiRightArrowAlt size={20} />
          </ActionIcon>
        </ActionIconGroup>
      </Group>
      <Carousel
        getEmblaApi={setEmbla}
        withControls={false}
        loop
        slideSize="33.333333%"
        slideGap="md"
        align="start"
        slidesToScroll={1}
      >
        {Array.isArray(currentRecommendations)
          ? currentRecommendations.map((rec) => (
              <Carousel.Slide key={rec.starter} display={"flex"}>
                <Recommendation
                  createReport={createReport}
                  starter={rec.starter}
                  explanation={rec.explanation}
                  type={rec.type}
                />
              </Carousel.Slide>
            ))
          : null}
      </Carousel>
    </Stack>
  );
};
