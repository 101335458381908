import type { ChatCompletionMessageParam } from "openai/resources/index.mjs";
import type { Simplify } from "type-fest";
import type { Database } from "./supabase/database-generated.types";
import { z } from "zod";

export type InsightRecordDb = Omit<
  Database["public"]["Tables"]["insight_records"]["Row"],
  "message"
> & {
  message: ChatCompletionMessageParam;
};

export type ArtifactDb = Omit<
  Database["public"]["Tables"]["artifacts"]["Row"],
  "data_sample"
> & {
  data_sample: Record<string, unknown>[] | null;
};

export type InsightRecordWithArtifacts = InsightRecordDb & {
  artifacts: ArtifactDb[];
};

export type InsightDb = Simplify<
  Database["public"]["Tables"]["gold_views"]["Row"] & {
    report: Database["public"]["Tables"]["reports"]["Row"] | null;
    messages: InsightRecordWithArtifacts[];
  }
>;

export type InsightList = Simplify<
  Database["public"]["Tables"]["gold_views"]["Row"] & {
    report: Database["public"]["Tables"]["reports"]["Row"] | null;
    artifacts: ArtifactDb[];
  }
>[];

export const ArtifactSuggestionsSchema = z
  .object({
    impact: z.string(),
    description: z.string(),
  })
  .array();

export type ArtifactSuggestion = z.infer<
  typeof ArtifactSuggestionsSchema
>[number];

export type InsightResponse = {
  insight: InsightDb;
  status: "final" | "pending";
};
