import { type APIEndpoint, fetchAPIWithToken } from "@hooks/use-client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type Sub = {
  preferences: {
    channel_types?: Record<PropertyKey, unknown>;
  } & Record<PropertyKey, unknown>;
};

export const useSubscription = () => {
  const queryClient = useQueryClient();

  const { data: subscriptionStatus, isLoading: isLoadingSubscription } =
    useQuery({
      queryKey: ["subscriptionStatus"],
      queryFn: async () => {
        const response = await fetchAPIWithToken("/api/subscription-status", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch subscription status");
        }

        return await response.json();
      },
    });

  const { mutate: toggleSubscription, isPending: isTogglingSubscription } =
    useMutation({
      mutationFn: async ({
        channel_type,
        subscribed,
      }: {
        channel_type?: string;
        subscribed: boolean;
      }) => {
        const endpoint = subscribed ? "/api/subscribe" : "/api/unsubscribe";
        const fullEndpoint: APIEndpoint = channel_type
          ? `${endpoint}/${channel_type}`
          : endpoint;

        const response = await fetchAPIWithToken(fullEndpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to ${subscribed ? "subscribe" : "unsubscribe"}`,
          );
        }

        return { channel_type, subscribed };
      },
      onMutate: async ({ channel_type, subscribed }) => {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update
        await queryClient.cancelQueries({ queryKey: ["subscriptionStatus"] });

        // Snapshot the previous value
        const previousStatus = queryClient.getQueryData(["subscriptionStatus"]);

        // Optimistically update to the new value
        queryClient.setQueryData(["subscriptionStatus"], (old: Sub) => {
          if (!channel_type) {
            return {
              ...old,
              isSubscribed: subscribed,
              preferences: {
                ...old.preferences,
                channel_types: subscribed ? old.preferences.channel_types : {},
              },
            };
          } else {
            return {
              ...old,
              preferences: {
                ...old.preferences,
                channel_types: {
                  ...old.preferences.channel_types,
                  [channel_type]: subscribed,
                },
              },
            };
          }
        });

        // Return a context object with the snapshotted value
        return { previousStatus };
      },
      onError: (_err, _newTodo, context) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          ["subscriptionStatus"],
          context?.previousStatus,
        );
      },
    });

  return {
    isSubscribed: subscriptionStatus?.isSubscribed ?? false,
    preferences: subscriptionStatus?.preferences ?? {},
    isLoadingSubscription,
    toggleSubscription,
    isTogglingSubscription,
  };
};
