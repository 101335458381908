import { useReportBuilder } from "@components/ReportBuilder/ReportBuilderContext";
import { Page } from "@layout/Page";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Mark,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import type { Report } from "@mm/shared/schemas/reports";
import { Steps } from "@pages/Steps";
import { useEffect, useState } from "react";
import { BiCheck, BiInfoCircle } from "react-icons/bi";
import {
  PiCheckCircleDuotone,
  PiCircleDashed,
  PiCircleDashedDuotone,
} from "react-icons/pi";
import classes from "./Box.module.css";
import { Review } from "./Review";

export const ReportOnboarding = ({ report }: { report: Report }) => {
  const { setIsOnboarded, isSettingOnboarded } = useReportBuilder();

  const [reviewing, setReviewing] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (!reviewing) {
      if (typeof window !== "undefined") {
        const visibilitychangeHandler = () => {
          if (document.visibilityState === "visible") {
            setIsActive(true);
          } else {
            setIsActive(false);
          }
        };

        window.addEventListener(
          "visibilitychange",
          visibilitychangeHandler,
          false,
        );

        return () => {
          window.removeEventListener(
            "visibilitychange",
            visibilitychangeHandler,
          );
        };
      }
    }
  }, [reviewing]);

  if (reviewing) {
    return (
      <Page
        rightSection={<Steps active={2} />}
        title={
          <Text size="xl" fw={"900"}>
            Preparing data for{" "}
            <Mark
              fz={"lg"}
              fw={"normal"}
              ff={"monospace"}
              bg={"gray.2"}
              ml={6}
              px={4}
              py={4}
              color="gray"
              style={{ borderRadius: "var(--mantine-radius-md)" }}
            >
              {report.report.name}
            </Mark>
          </Text>
        }
      >
        <Review report={report} />
      </Page>
    );
  }

  const initialMessage = report.messages[0]?.message?.content?.[0];
  const message = (() => {
    if (!initialMessage) return "";
    if (typeof initialMessage == "string") return initialMessage;

    if ("text" in initialMessage) return initialMessage.text;

    return "";
  })();

  return (
    <Page rightSection={<Steps active={1} />}>
      <Stack pb={"sm"} flex={1} align="center" gap={"xl"}>
        <Group gap={"xs"}>
          <Badge
            color="green"
            tt={"none"}
            fw={"normal"}
            radius={"md"}
            size="xl"
            variant="light"
            leftSection={<BiCheck size={24} />}
          >
            Done analyzing your request
          </Badge>
        </Group>
        <Text size="xl" fw={"900"}>
          Here is my analysis of your objectives, and next steps:
        </Text>
        <Stack gap={"xs"} align="flex-end">
          <Tooltip multiline maw={"25rem"} label={message}>
            <Group gap={4} style={{ cursor: "pointer" }}>
              <Text size="sm" c={"dimmed"}>
                your request
              </Text>
              <ActionIcon size={"sm"} c="dimmed" variant="transparent">
                <BiInfoCircle size={16} />
              </ActionIcon>
            </Group>
          </Tooltip>
          <Grid
            gutter={0}
            w={900}
            style={{ borderRadius: "var(--mantine-radius-md)" }}
            bd={"1px solid var(--mantine-color-gray-5)"}
          >
            <Grid.Col span={4}>
              <Box
                data-active={isActive}
                className={classes.box}
                h={"100%"}
                p={"sm"}
                style={{
                  borderBottom: "1px solid var(--mantine-color-gray-5)",
                }}
              >
                <Group align="flex-start" gap={"xs"}>
                  <PiCheckCircleDuotone
                    size={28}
                    color="var(--mantine-primary-color-5)"
                  />
                  <Stack flex={1} gap={"xs"}>
                    <Text size="lg" fw={700}>
                      Set your goal
                    </Text>
                    <Text>{report.report.goal}</Text>
                  </Stack>
                </Group>
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Box
                data-active={isActive}
                className={classes.box}
                h={"100%"}
                p={"sm"}
                style={{
                  borderBottom: "1px solid var(--mantine-color-gray-5)",
                  borderLeft: "1px solid var(--mantine-color-gray-5)",
                  borderRight: "1px solid var(--mantine-color-gray-5)",
                }}
              >
                <Group align="flex-start" gap={"xs"}>
                  <PiCircleDashedDuotone
                    size={28}
                    color="var(--mantine-primary-color-5)"
                  />
                  <Stack flex={1} gap={"xs"}>
                    <Text size="lg" fw={700}>
                      Prepare data
                    </Text>
                    <Text>{report.report.purpose}</Text>
                  </Stack>
                </Group>
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Box
                data-active={isActive}
                className={classes.box}
                h={"100%"}
                p={"sm"}
                style={{
                  borderBottom: "1px solid var(--mantine-color-gray-5)",
                }}
              >
                <Group align="flex-start" gap={"xs"}>
                  <PiCircleDashed
                    size={28}
                    color="var(--mantine-color-gray-5)"
                  />
                  <Stack flex={1} gap={"xs"}>
                    <Text size="lg" fw={700}>
                      Draw insights
                    </Text>
                    <Text>{report.report.analysis_plan}</Text>
                  </Stack>
                </Group>
              </Box>
            </Grid.Col>
            <Grid.Col span={12}>
              <Box h={"100%"} px={"md"} py={"lg"}>
                <Group gap={"xl"}>
                  <Text flex={1} ta={"right"}>
                    Let's start by reviewing the {report.columns.length} column
                    {report.columns.length > 1 ? "s" : ""} I identified
                    <br />
                    for data preparation
                  </Text>
                  <Button
                    disabled={isSettingOnboarded}
                    onClick={() => setReviewing(true)}
                  >
                    Review columns
                  </Button>
                </Group>
              </Box>
            </Grid.Col>
          </Grid>
        </Stack>
        <Button
          size="xs"
          variant="transparent"
          loading={isSettingOnboarded}
          onClick={setIsOnboarded}
        >
          Skip to data preparation
        </Button>
      </Stack>
    </Page>
  );
};
