import { z } from "@hono/zod-openapi";
import { ISODate } from "@mm/shared/schemas/utils";

/**
 * Gold related config
 */

export const BIG_QUERY_ERROR = "BIG_QUERY_ERROR";
export const INTERNAL_ERROR = "INTERNAL_ERROR";

export const GoldViewSupabaseSchema = z
  .object({
    id: z.number(),
    created_at: ISODate(),
    user_id: z.string(),
    table_name: z.string().nullable(),
    table_display_name: z.string().nullable(),
    sql: z.string().nullable(),
    status: z.string(),
    error: z.string().nullable(),
    metadata: z.record(z.any()).array().nullable(),
  })
  .openapi("GoldColumn");

export type GoldView = z.infer<typeof GoldViewSupabaseSchema>;

export const GoldViewDataSchema = z
  .object({})
  .passthrough()
  .array()
  .openapi("GoldViewData");

export type GoldViewTableData = z.infer<typeof GoldViewDataSchema>;

export const PENDING_FINAL = "PENDING_FINAL";
export const PENDING_DRAFT = "PENDING_DRAFT";
export const ERROR_FINAL = "ERROR.FINAL";
export const FINAL = "FINAL";
export const DRAFT = "DRAFT";

export type GoldViewStatuses =
  | typeof PENDING_DRAFT
  | typeof PENDING_FINAL
  | typeof ERROR_FINAL
  | typeof DRAFT;
