import {
  Group,
  Table as MantineTable,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  formatDataValue,
  isFormattedData,
  type FormattedData,
} from "@mm/shared/data/FormattedData";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  type OnChangeFn,
  type Row,
  type SortingState,
  type Table as TanStackTable,
} from "@tanstack/react-table";
import cx from "clsx";
import React, { useCallback, useMemo, useRef, useState } from "react";
import type { ColumnsResult, DataResult } from "../DataPreview";
import classes from "./Table.module.css";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";

const MAX_CELL_CHAR_LENGTH = 35;
/*
 * Compare the values from `rowA` and `rowB` for column `columnId`
 */
const sortingFn = (
  rowA: Row<Record<string, FormattedData>>,
  rowB: Row<Record<string, FormattedData>>,
  columnId: string,
) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);

  const sortValueA = isFormattedData(a) ? a.sortValue : null;
  const sortValueB = isFormattedData(b) ? b.sortValue : null;

  // Handle null values
  if (sortValueA === null && sortValueB === null) return 0;
  if (sortValueA === null) return -1;
  if (sortValueB === null) return 1;

  // Handle dates
  if (sortValueA instanceof Date && sortValueB instanceof Date) {
    return sortValueA.getTime() - sortValueB.getTime();
  }

  // Handle numbers
  if (typeof sortValueA === "number" && typeof sortValueB === "number") {
    return sortValueA - sortValueB;
  }

  // Default string comparison
  return String(sortValueA).localeCompare(String(sortValueB));
};

// Display component that just renders the pre-formatted display value
const CellContent: React.FC<{
  formattedValue: FormattedData;
}> = ({ formattedValue: { display } }) => {
  if (display === "empty") {
    return (
      <Text c="dimmed" size="sm" truncate="end">
        empty
      </Text>
    );
  }

  if (display && display.length > MAX_CELL_CHAR_LENGTH) {
    return (
      <Tooltip multiline maw="25rem" label={display}>
        <Text truncate="end">{display}</Text>
      </Tooltip>
    );
  }

  return <Text truncate="end">{display}</Text>;
};

const headerContent =
  ({ name }: { name: string }) =>
  () => {
    return (
      <Tooltip multiline maw="25rem" label={name}>
        <Text fw="bold" truncate="end">
          {name}
        </Text>
      </Tooltip>
    );
  };

export type TableFilters = Record<string, string | number>;

type TableProps = {
  fetchMoreOnBottomReached: (e: HTMLDivElement) => void;
  dataColumns: ColumnsResult["data"];
  data: DataResult["data"];
  onSortingChange?: (sorting: SortingState) => void;
  onFiltersChange?: (filters: TableFilters) => void;
  initialSorting?: SortingState;
  initialFilters?: TableFilters;
  emptyChildren?: React.ReactNode;
};

const columnHelper = createColumnHelper<Record<string, FormattedData>>();

const TableContent = React.memo(
  ({
    table,
    data,
  }: {
    table: TanStackTable<Record<string, FormattedData>>;
    data: Record<string, unknown>[] | undefined;
  }) => {
    return data && data.length > 0 && <TableBody table={table} />;
  },
);

export const Table: React.FC<TableProps> = ({
  fetchMoreOnBottomReached,
  dataColumns,
  data,
  onSortingChange,
  onFiltersChange,
  initialFilters = {},
  initialSorting = [],
  emptyChildren,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [filters, setFilters] = useState<TableFilters>(initialFilters);
  const [scrolled, setScrolled] = useState(false);

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const newSorting =
        typeof updater === "function" ? updater(sorting) : updater;
      setSorting(newSorting);
      onSortingChange?.(newSorting);
    },
    [sorting, onSortingChange],
  );

  const handleFiltersChange = useCallback(
    (newFilters: TableFilters) => {
      setFilters(newFilters);
      onFiltersChange?.(newFilters);
    },
    [onFiltersChange],
  );

  // Process the data once, memoizing both display and sort values
  const processedData = useMemo(() => {
    if (!data) return [];

    return data.map((row) => {
      const processedRow: Record<string, FormattedData> = {};

      for (const [key, value] of Object.entries(row)) {
        processedRow[key] = formatDataValue(value);
      }

      return processedRow;
    });
  }, [data]);

  const columns = useMemo(() => {
    if (dataColumns && dataColumns.length > 0) {
      return dataColumns.map(({ name }) =>
        columnHelper.accessor(name, {
          cell: (info) => <CellContent formattedValue={info.getValue()} />,
          header: headerContent({ name }),
          sortingFn: sortingFn,
        }),
      );
    }
    return [];
  }, [dataColumns]);

  const table = useReactTable({
    data: processedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
    },
    manualSorting: true,
    onSortingChange: handleSortingChange,
  });

  return (
    <Stack flex={1} style={{ overflow: "hidden" }}>
      <MantineTable.ScrollContainer
        ref={tableContainerRef}
        minWidth={0}
        flex={1}
        onScrollCapture={(e) =>
          fetchMoreOnBottomReached(e.target as HTMLDivElement)
        }
        // @ts-expect-error Mantine does not expose this inside the table, but it's available
        onScrollPositionChange={({ y }: { y: number }) => setScrolled(y !== 0)}
      >
        <MantineTable
          stickyHeader
          verticalSpacing="xs"
          withColumnBorders
          withRowBorders
        >
          <TableHeader
            className={cx(
              classes.header,
              classes.scrolled ? { [classes.scrolled]: scrolled } : {},
            )}
            table={table}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
          {/* Here we are using a React.memo because we don't want to repain the
          content of the table if something unrelated changes in the parent
          We use this to avoid re-rendering the table body when `scrolled` is changed
          as it's only used by the table header
          */}
          <TableContent table={table} data={data} />
        </MantineTable>
      </MantineTable.ScrollContainer>
      {/* Do not render the empty status inside the table */}
      {data && !data.length && (
        <Group
          flex={1}
          style={{ justifyContent: "center", alignContent: "flex-start" }}
        >
          {emptyChildren}
        </Group>
      )}
    </Stack>
  );
};
