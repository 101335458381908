import { createTheme } from "@mantine/core";

// Supports weights 100-900
import "@fontsource-variable/inter";

import "@fontsource/ibm-plex-serif";

// Supports weights 100-700
import "@fontsource-variable/roboto-mono";

export const theme: ReturnType<typeof createTheme> = createTheme({
  primaryColor: "cyan",
  fontFamily: '"Inter Variable", sans-serif',
  fontFamilyMonospace: '"Roboto Mono Variable", monospace',
  headings: { fontFamily: '"IBM Plex Serif", serif' },
});
