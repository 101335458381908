import type { InsightDb } from "@mm/shared/schemas/insights";
import { Chat } from "./Chat";
import { DataPreview } from "./DataPreview";

export const Insight = ({ insight }: { insight: InsightDb }) => {
  return (
    <>
      <Chat insightId={insight.id} messages={insight.messages} />
      <DataPreview insight={insight} />
    </>
  );
};
